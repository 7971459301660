import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import demoVideo from "../../assets/Home/IMG_0.mp4";
import phoneImg from "../../assets/Home/iphone_frame.webp";
import { CiSearch, CiMobile4 } from "react-icons/ci";
import { TfiCommentsSmiley } from "react-icons/tfi";
import "../../App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Alysha() {
  useEffect(() => {
    toast("Wow so easy!");
  }, []);
  return (
    <>
      <ToastContainer />
      <br />
      <br />
      <section className="section gradient-banner">
        <div className="shapes-container">
          <div
            className="shape"
            data-aos="fade-down-left"
            data-aos-duration="1500"
            data-aos-delay="100"
          ></div>
          <div
            className="shape"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-delay="100"
          ></div>
          <div
            className="shape"
            data-aos="fade-up-right"
            data-aos-duration="1000"
            data-aos-delay="200"
          ></div>
          <div
            className="shape"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          ></div>
          <div
            className="shape"
            data-aos="fade-down-left"
            data-aos-duration="1000"
            data-aos-delay="100"
          ></div>
          <div
            className="shape"
            data-aos="fade-down-left"
            data-aos-duration="1000"
            data-aos-delay="100"
          ></div>
          <div
            className="shape"
            data-aos="zoom-in"
            data-aos-duration="1000"
            data-aos-delay="300"
          ></div>
          <div
            className="shape"
            data-aos="fade-down-right"
            data-aos-duration="500"
            data-aos-delay="200"
          ></div>
          <div
            className="shape"
            data-aos="fade-down-right"
            data-aos-duration="500"
            data-aos-delay="100"
          ></div>
          <div
            className="shape"
            data-aos="zoom-out"
            data-aos-duration="2000"
            data-aos-delay="500"
          ></div>
          <div
            className="shape"
            data-aos="fade-up-right"
            data-aos-duration="500"
            data-aos-delay="200"
          ></div>
          <div
            className="shape"
            data-aos="fade-down-left"
            data-aos-duration="500"
            data-aos-delay="100"
          ></div>
          <div
            className="shape"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="0"
          ></div>
          <div
            className="shape"
            data-aos="fade-down"
            data-aos-duration="500"
            data-aos-delay="0"
          ></div>
          <div
            className="shape"
            data-aos="fade-up-right"
            data-aos-duration="500"
            data-aos-delay="100"
          ></div>
          <div
            className="shape"
            data-aos="fade-down-left"
            data-aos-duration="500"
            data-aos-delay="0"
          ></div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-6 order-2 order-md-1 text-center text-md-left aiysha-hero-text"
              style={{ zIndex: 1 }}
            >
              <h1 className="text-white font-weight-bold mb-4 aiysha-is-here">
                <span style={{ color: "#9A002E" }}>Aiysha</span> is Here{" "}
              </h1>
              <p className="text-white mb-5">
                Learn about new products, try them on virtually, and ask all
                your beauty questions, instantly with our AI agent - Aiysha - on
                WhatsApp.
              </p>
              <a
                target="_blank"
                href="https://aiysha.robomua.com/"
                className="btn checkitout"
              >
                Check It Out
              </a>
            </div>
            <div className="col-md-6 text-center order-1 order-md-2">
              <div className="alysha-vid-div">
                <div className="aisha-background-color-overlay"></div>

                <div className="img-video-div">
                  <img src={phoneImg} className="iphone-frame" />
                  <video
                    aria-label="roboMUA APP demo"
                    muted
                    loop
                    autoPlay
                    playsInline
                    className="video-div"
                  >
                    <source
                      src={demoVideo}
                      className="video"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section pt-0 position-relative pull-top">
        <div className="container">
          <div className="rounded shadow p-5 bg-white">
            <div className="row">
              <div className="col-lg-4 col-md-6 mt-5 mt-md-0 text-center">
                <CiSearch size={40} className="hang-icon" />
                <h3 className="mt-4 text-capitalize h5 ">
                  discover new products
                </h3>
                <p className="regular text-muted">
                  Get the scoop on the newest products and trends in the beauty
                  world with AIySha's expert guidance. From skincare to makeup,
                  stay ahead of the curve with personalized recommendations.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 mt-5 mt-md-0 text-center">
                <CiMobile4 size={40} className="hang-icon" />
                <h3 className="mt-4 text-capitalize h5 ">virtual try-on</h3>
                <p className="regular text-muted">
                  See how products look on you without ever leaving home!
                  AIySha's virtual try-on feature lets you experiment with
                  different shades and styles to find your perfect match.
                </p>
              </div>
              <div className="col-lg-4 col-md-12 mt-5 mt-lg-0 text-center">
                <TfiCommentsSmiley size={40} className="hang-icon" />
                <h3 className="mt-4 text-capitalize h5 ">ask AIySha</h3>
                <p className="regular text-muted">
                  Stuck on a beauty dilemma? AIySha's got you covered! Ask her
                  anything, from product advice to skincare routines, and get
                  instant answers to help you glow.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import Emmanuel from "../assets/teampics/emma.webp";
import deby from "../assets/teampics/derb.webp";
import rofy from "../assets/teampics/rofy.webp";
import charles from "../assets/teampics/charles.webp";
import salma from "../assets/teampics/raj.webp";
import randy from "../assets/teampics/randy.webp";
import Footer from "../components/General/Footer/Footer";
import NavigationBar from "../components/General/Navbar/NavigationBar";
import { FaLinkedin } from "react-icons/fa";

const Team = () => {
  return (
    <>
      <NavigationBar />
      <br />
      <br />
      <br />
      <br />

      {/* Page Title Section */}
      <div className="row team-div">
        <div className="col-lg-12">
          <div className="section-title">
            <h2>Meet the ySquad</h2>
            <p>
              A dynamic team of innovators and problem-solvers dedicated to
              revolutionizing AI solutions for diverse skin shades and types...
            </p>
          </div>
        </div>
      </div>

      {/* Founders Section */}
      <section className="founders  pt-0">
        <div className="container">
          <div className="row" style={{ padding: "1rem" }}>
            <div className="col-lg-4">
              <div>
                <img
                  src={Emmanuel}
                  alt="Emmanuel Acheampong"
                  className="w-100"
                />
                <a
                  href="https://www.linkedin.com/in/emmanuel-acheampong/"
                  className="team-member-link"
                  target="_blank"
                >
                  <h3 className="team-member-h3">
                    Emmanuel Acheampong <FaLinkedin />
                  </h3>
                </a>
                <p className="team-member-role">Co-Founder & CEO</p>
                <p className="team-member-role-desc">
                  With a passion for innovation and a deep understanding of
                  artificial intelligence, Emmanuel has spearheaded the
                  development of cutting-edge AI algorithms that cater to the
                  diverse needs of individuals across various industries. His
                  expertise and leadership are poised to drive further
                  advancements in AI, fostering innovation across various
                  industries.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <img src={deby} alt="Derby Chukwudi" className="w-100" />
                <a
                  href="https://www.linkedin.com/in/derbyc/"
                  className="team-member-link"
                  target="_blank"
                >
                  <h3 className="team-member-h3">
                    Derby Chukwudi <FaLinkedin />
                  </h3>
                </a>
                <p className="team-member-role">Co-Founder & COO</p>
                <p className="team-member-role-desc">
                  As a passionate advocate for inclusivity and diversity, Derby,
                  current Miss New Jersey USA, brings her expertise in the
                  beauty and pageantry world to yShade. Having experienced
                  firsthand the importance of representation, she is dedicated
                  to helping yShade revolutionize the industry with AI
                  technology that celebrates diverse skin shades and types, and
                  promotes inclusivity for all.
                </p>
              </div>
            </div>

            <div className="col-lg-4">
              <div>
                <img src={rofy} alt="Rofy Okyere-Forson" className="w-100" />
                <a
                  href="https://www.linkedin.com/in/rofyray//"
                  className="team-member-link"
                  target="_blank"
                >
                  <h3 className="team-member-h3">
                    Rofy Okyere-Forson <FaLinkedin />
                  </h3>
                </a>
                <p className="team-member-role">Co-Founder & CTO</p>
                <p className="team-member-role-desc">
                  Rofy is a full-stack engineer driven by a passion for social
                  impact, dedicating his expertise to projects that create
                  positive change in society and people's lives. He contributes
                  to yShade's mission to revolutionize inclusivity in AI
                  technology. When not coding, Rofy can be found volunteering
                  for causes he cares about. He is part software engineer, part
                  entrepreneur, part philanthropist.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="  pt-0">
        <div className="container">
          <div className="row" style={{ padding: "1rem" }}>
            <div className="col-lg-4 col-md-6">
              <div>
                <img src={charles} alt="Charles Arday" className="w-100" />
                <a
                  href="https://www.linkedin.com/in/charlesarday/"
                  className="team-member-link"
                  target="_blank"
                >
                  <h3 className="team-member-h3">
                    Charles Arday <FaLinkedin />
                  </h3>
                </a>
                <p className="team-member-role">Head Of Product</p>
                <p className="team-member-role-desc">
                  Driving the development of innovative solutions that meet the
                  needs of diverse skin shades and types. With a background in
                  user research and product management, Charles is dedicated to
                  creating products that make a positive impact.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div>
                <img src={salma} alt="Salma Raji" className="w-100" />
                <a
                  href="https://www.linkedin.com/in/salma-raji-bb948a191/"
                  className="team-member-link"
                  target="_blank"
                >
                  <h3 className="team-member-h3">
                    Salma Raji <FaLinkedin />
                  </h3>
                </a>
                <p className="team-member-role">Head Of Design</p>
                <p className="team-member-role-desc">
                  Crafting intuitive and inclusive user experiences that empower
                  individuals to take control of their skin health. With a
                  passion for human-centered design, Salma brings a unique
                  perspective to the team.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div>
                <img src={randy} alt="Randy Davoh" className="w-100" />
                <a
                  href="https://www.linkedin.com/in/randy-davoh/"
                  className="team-member-link"
                  target="_blank"
                >
                  <h3 className="team-member-h3">
                    Randy Davoh <FaLinkedin />
                  </h3>
                </a>
                <p className="team-member-role">Software Engineer</p>
                <p className="team-member-role-desc">
                  Leads and maintains the frontend development of yShade’s
                  product suite, including the web platform, cross-platform app,
                  and Chrome extension, ensuring seamless user experiences and
                  innovative interfaces that drive engagement and enhance
                  accessibility.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Team;

import React from "react";
import CarouselSlider from "../components/Home/Slider/CarouselSlider";
import Alysha from "../components/Home/Alysha";
import Footer from "../components/General/Footer/Footer";
import NavigationBar from "../components/General/Navbar/NavigationBar";
import "../App.css";
import DontBelieve from "../components/Home/DontBelieve";
import GotYou from "../components/Home/GotYou";

export default function HomePage() {
  return (
    <div>
      <NavigationBar />
      <CarouselSlider />
      <Alysha />
      <GotYou />
      <DontBelieve />
      <Footer />
    </div>
  );
}

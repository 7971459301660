import SlideImage1 from "../../../assets/Home/carouImg1.webp";
import SlideImage2 from "../../../assets/Home/carouImg3.webp";
import SlideImage3 from "../../../assets/Home/newCarou.jpeg";

export const SliderData = [
  // <Strong></Strong/>
  {
    id: 1,
    img: SlideImage1,
    topCaption: "AI Models",
    text: `Discover our cutting-edge
          <Strong>AI technology</Strong/> tailored for
          <Strong>every</Strong/> skin tone. 
          Our <Strong>predictive</Strong/> models expertly
          match consumers with their ideal shades, while our 
          <Strong>generative</Strong/>  models
          revolutionize virtual try-ons.`,
    caption: "AI Models",
    button1Texts: "APIs",
    button2Texts: "Try Demo",
    link: "https://docs.robomua.com/",
    link2: "/demo",
  },

  {
    id: 2,
    img: SlideImage2,
    topCaption: "Try Aiysha",
    text: `Explore our advanced <Strong>multimodal</Strong/>
               beauty and fashion AI assistant <Strong>AIySha</Strong/>,
              powered by a state-of-the-art <Strong>LLM</Strong/>.`,
    caption: "AI Agents",
    button1Texts: "Try Aiysha",
    link: "https://aiysha.robomua.com/",
  },

  {
    id: 3,
    img: SlideImage3,
    topCaption: "yShade 100",
    text: `Our industry pioneering inclusive dataset, <Strong>yShade 100</Strong/>, 
        consists of <Strong>10 million </Strong/>meticulously curated <Strong>images, </Strong/> 
        precisely labeled across <Strong>100 diverse</Strong/> skin tones.`,
    caption: "yShade 100",
    button1Texts: "Learn More",
    link: "/about",
  },
];
